import AEDicon from '../../images/AEDicon.jpg';

const NCStateAEDs = [
    {
        id: 110,        
        name: "Student Health",
        address: "---",
        position: { lat: 35.78474, lng: -78.67524},
        description: "Treatment clinic on crash cart",
        image: AEDicon, //doesn't work
    },
    {
        id: 111,        
        name: "Student Health",
        address: "---",
        position: { lat: 35.78478, lng:-78.6754 },
        description: "2nd fl outside Room 2010",
        image: AEDicon, //doesn't work
    },
    {
        id: 112,        
        name: "Vaughn Towers",
        address: "---",
        position: { lat: 35.80025, lng:-78.72033 },
        description: "Club level between elevator and concession stand",
        image: AEDicon, //doesn't work
    },
    {
        id: 113,        
        name: "Vaughn Towers",
        address: "---",
        position: { lat: 35.80036, lng:-78.72044 },
        description: "2nd fl west wall between elevator lobbies",
        image: AEDicon, //doesn't work
    },
    {
        id: 114,        
        name: "Vaughn Towers",
        address: "---",
        position: { lat: 35.80037, lng:-78.72045 },
        description: "3rd fl west wall between elevator lobbies",
        image: AEDicon, //doesn't work
    },
    {
        id: 115,        
        name: "Vaughn Towers",
        address: "---",
        position: { lat: 35.800226, lng:-78.720307 },
        description: "4th Floor in Public Safety Office",
        image: AEDicon, //doesn't work
    },
    {
        id: 116,        
        name: "Murphy Football Center",
        address: "---",
        position: { lat: 35.799866, lng:-78.718473 },
        description: "2nd Floor in weight room beside Room 213",
        image: AEDicon, //doesn't work
    },
    {
        id: 117,        
        name: "DH Hill",
        address: "---",
        position: { lat: 35.787587, lng: -78.669871},
        description: "Ground floor east near elevator 3 - Across from Room G137",
        image: AEDicon, //doesn't work
    },
    {
        id: 118,        
        name: "DH Hill",
        address: "---",
        position: { lat: 35.787587, lng:-78.669871 },
        description: "Circulation lobby by North Bookstack Stairs - Behind Book Collection Counter",
        image: AEDicon, //doesn't work
    },
    {
        id: 119,
        name: "DH Hill",
        address: "---",
        position: { lat: 35.787587, lng:-78.669871 },
        description: "1rst floor West Wing beside Room 1302 Hillsborough Street Side",
        image: AEDicon, //doesn't work
    },
    {
        id: 120,
        name: "DH Hill",
        address: "---",
        position: { lat: 35.787587, lng: -78.669871},
        description: "Hill-O-Beans - Dining Area",
        image: AEDicon, //doesn't work
    },
    {
        id: 121,
        name: "DH Hill",
        address: "---",
        position: { lat: 35.787587, lng: -78.669871},
        description: "3rd Floor East Wing Outside Elev #3 Across from Room 3130",
        image: AEDicon, //doesn't work
    },
    {
        id: 122,
        name: "DH Hill",
        address: "---",
        position: { lat: 35.787587, lng: -78.669871},
        description: "6th Floor South Tower Elevator 1 and 2 Lobby",
        image: AEDicon, //doesn't work
    },
    {
        id: 123,
        name: "PD",
        address: "---",
        position: { lat: 35.78547, lng: -78.68212},
        description: "Room 131 Uniform Patrol Operations",
        image: AEDicon, //doesn't work
    },
    {
        id: 124,
        name: "Cates Steam Plant",
        address: "---",
        position: { lat: 35.78415, lng: -78.67447},
        description: "2nd Floor Break Room 204",
        image: AEDicon, //doesn't work
    },
    {
        id: 125,
        name: "YB Steam Plant",
        address: "---",
        position: { lat: 35.78438, lng:-78.66754 },
        description: "2nd Floor Control Room",
        image: AEDicon, //doesn't work
    },
    {
        id: 126,
        name: "Yarbrough Steam Plant",
        address: "---",
        position: { lat: 35.78437, lng: -78.66755},
        description: "Inside Room 111 or on a truck",
        image: AEDicon, //doesn't work
    },
    {
        id: 127,
        name: "West Chiller",
        address: "---",
        position: { lat: 35.78749, lng: -78.68425},
        description: "2nd Floor Control Room",
        image: AEDicon, //doesn't work
    },
    {
        id: 128,
        name: "Cent. Utility Plant",
        address: "---",
        position: { lat: 35.775616, lng: -78.673741},
        description: "Control Room - Room 111",
        image: AEDicon, //doesn't work
    },
    {
        id: 129,
        name: "CBC Utility Plant",
        address: "---",
        position: { lat: 35.800393, lng: -78.70447},
        description: "Inside CBC Power Plant Room G-208",
        image: AEDicon, //doesn't work
    },
    {
        id: 130,
        name: "EB 1",
        address: "---",
        position: { lat: 35.771616, lng: -78.674949},
        description: "Outside of suite 2001",
        image: AEDicon, //doesn't work
    },
    {
        id: 131,
        name: "Reynolds",
        address: "---",
        position: { lat: 35.78343, lng: -78.67002},
        description: "Inside court area by SE stairs",
        image: AEDicon, //doesn't work
    },
    {
        id: 132,
        name: "Reynolds",
        address: "---",
        position: { lat: 35.783363, lng: -78.67008},
        description: "Outside room G07",
        image: AEDicon, //doesn't work
    },
    {
        id: 133,
        name: "Reynolds",
        address: "---",
        position: { lat: 35.783984, lng: -78.669881 },
        description: "Inside room 101",
        image: AEDicon, //doesn't work
    },
    {
        id: 134,
        name: "Talley",
        address: "---",
        position: { lat: 35.78371, lng: -78.67079},
        description: "Atrium by Information Desk - Outside of Room 2127",
        image: AEDicon, //doesn't work
    },
    {
        id: 135,
        name: "Talley",
        address: "---",
        position: { lat: 35.78398, lng: -78.67081},
        description: "3rd floor by room 3254",
        image: AEDicon, //doesn't work
    },
    {
        id: 136,
        name: "512 Brickhaven",
        address: "---",
        position: { lat: 35.79448, lng: -78.69644},
        description: "1st floor by elevator - Left of front entrance",
        image: AEDicon, //doesn't work
    },
    {
        id: 137,
        name: "Witherspoon",
        address: "---",
        position: { lat: 35.78532, lng: -78.67442},
        description: "Outside hall by room 118",
        image: AEDicon, //doesn't work
    },
    {
        id: 138,
        name: "McKimmon",
        address: "---",
        position: { lat: 35.78286, lng: -78.68527},
        description: "2nd floor outside room 231",
        image: AEDicon, //doesn't work
    },
    {
        id: 139,
        name: "McKimmon",
        address: "---",
        position: { lat: 35.78305, lng: -78.68528},
        description: "1st floor outside room 10",
        image: AEDicon, //doesn't work
    },
    {
        id: 140,
        name: "McKimmon",
        address: "---",
        position: { lat: 35.78316, lng: -78.68549},
        description: "1st floor inside room 145 in file cabinet",
        image: AEDicon, //doesn't work
    },
    {
        id: 141,
        name: "Pullen Hall",
        address: "---",
        position: { lat: 35.78577, lng: -78.67489},
        description: "Inside Suite 1104 - beside room 1115",
        image: AEDicon, //doesn't work
    },
    {
        id: 142,
        name: "Poe Hall",
        address: "---",
        position: { lat: 35.785802, lng: -78.6664},
        description: "Outside room 208 Deans Office",
        image: AEDicon, //doesn't work
    },
    {
        id: 143,
        name: "Poe Hall",
        address: "---",
        position: { lat: 35.785703, lng: -78.666187},
        description: "Outside room 400 Media Library",
        image: AEDicon, //doesn't work
    },
    {
        id: 144,
        name: "Poe Hall",
        address: "---",
        position: { lat: 35.785815, lng: -78.666428},
        description: "Outside room 640 Psychology Office",
        image: AEDicon, //doesn't work
    },
    {
        id: 145,
        name: "Friday Institute",
        address: "---",
        position: { lat: 35.758638, lng: -78.687856},
        description: "1st floor by elevators and restrooms",
        image: AEDicon, //doesn't work
    },
    {
        id: 146,
        name: "Friday Institute",
        address: "---",
        position: { lat: 35.758652, lng: -78.688013},
        description: "2nd Floor Outside Rm 215",
        image: AEDicon, //doesn't work
    },
    {
        id: 147,
        name: "Carmichael Rec",
        address: "---",
        position: { lat: 35.78275, lng: -78.67164},
        description: "1st Floor Stairwell Landing",
        image: AEDicon, //doesn't work
    },
    {
        id: 148,
        name: "Carmichael Rec",
        address: "---",
        position: { lat: 35.78274, lng: -78.6716},
        description: "2nd Floor Stairwell Landing",
        image: AEDicon, //doesn't work
    },
    {
        id: 149,
        name: "Carmichael Rec",
        address: "---",
        position: { lat: 35.78267, lng: -78.67142},
        description: "University Rec -3rd Floor Inside Room 3602",
        image: AEDicon, //doesn't work
    },
    {
        id: 150,
        name: "Aquatic Center",
        address: "---",
        position: { lat: 35.78272, lng: -78.67219},
        description: "50 Meter Pool Deck by Room 1505A",
        image: AEDicon, //doesn't work
    },
    {
        id: 151,
        name: "Aquatic Center",
        address: "---",
        position: { lat: 35.78295, lng: -78.67243},
        description: "Lifeguards Office Room 1403A",
        image: AEDicon, //doesn't work
    },
    {
        id: 152,
        name: "Miller Field",
        address: "---",
        position: { lat: 35.78162, lng: -78.67233},
        description: "Miller Field House",
        image: AEDicon, //doesn't work
    },
    {
        id: 153,
        name: "Miller Field",
        address: "---",
        position: { lat: 35.78162, lng: -78.672324},
        description: "Miller Field House",
        image: AEDicon, //doesn't work
    },
    {
        id: 154,
        name: "Method Road",
        address: "---",
        position: { lat: 35.78814, lng: -78.6723},
        description: "Method Road Fieldhouse",
        image: AEDicon, //doesn't work
    },
    {
        id: 155,
        name: "University Rec",
        address: "---",
        position: { lat: 35.76392, lng: -78.67081},
        description: "Centennial Upper Field Inside Shed",
        image: AEDicon, //doesn't work
    },
    {
        id: 156,
        name: "CVM",
        address: "---",
        position: { lat: 35.79861, lng: -78.70443},
        description: "Animal Scan - Main Entrance in Hallway",
        image: AEDicon, //doesn't work
    },
    {
        id: 157,
        name: "CVM",
        address: "---",
        position: { lat: 35.79852, lng: -78.70363},
        description: "Common Area by Room C120",
        image: AEDicon, //doesn't work
    },
    {
        id: 158,
        name: "CVM",
        address: "---",
        position: { lat: 35.79775, lng: -78.70366},
        description: "Outside of Room A202 - CVM Main in Hallway",
        image: AEDicon, //doesn't work
    },
    {
        id: 159,
        name: "CVM",
        address: "---",
        position: { lat: 35.79828, lng: -78.70364},
        description: "3rd Floor hallway between Pods B and C",
        image: AEDicon, //doesn't work
    },
    {
        id: 160,
        name: "Thompson Theatre	Beside Concession Stand - Right hallway",
        address: "---",
        position: { lat: 35.78287, lng: -78.66673},
        description: "Beside Concession Stand - Right hallway",
        image: AEDicon, //doesn't work
    },
    {
        id: 161,
        name: "Hillsborough Bldg",
        address: "---",
        position: { lat: 35.78858, lng: -78.67055},
        description: "Lobby off Hillsborough Street",
        image: AEDicon, //doesn't work
    },
    {
        id: 162,
        name: "AFC Tech",
        address: "---",
        position: { lat: 35.77899, lng: -78.67756},
        description: "Front Lobby - Beside Room 212",
        image: AEDicon, //doesn't work
    },
    {
        id: 163,
        name: "BTEC-Keystone",
        address: "---",
        position: { lat: 35.77291, lng: -78.67385},
        description: "Lab 106 on Column by Office",
        image: AEDicon, //doesn't work
    },
    {
        id: 164,
        name: "BTEC",
        address: "---",
        position: { lat:35.77319, lng: -78.67397},
        description: "Second Floor by Room 248",
        image: AEDicon, //doesn't work
    },
    {
        id: 165,
        name: "BTEC",
        address: "---",
        position: { lat: 35.77309, lng: -78.67367},
        description: "First Floor by Elevator and Room 132",
        image: AEDicon, //doesn't work
    },
    {
        id: 166,
        name: "Biltmore Hall",
        address: "---",
        position: { lat: 35.78218, lng: -78.67728},
        description: "Lower Landing beside Room 1026A",
        image: AEDicon, //doesn't work
    },
    {
        id: 167,
        name: "Biltmore Hall",
        address: "---",
        position: { lat: 35.782195, lng: -78.677318},
        description: "Beside Room 2105B",
        image: AEDicon, //doesn't work
    },
    {
        id: 168,
        name: "Hunt Library",
        address: "---",
        position: { lat: 35.76906, lng: -78.67645},
        description: "Level 4 Library Elevator Lobby",
        image: AEDicon, //doesn't work
    },
    {
        id: 169,
        name: "Hunt Library",
        address: "---",
        position: { lat: 35.76912, lng: -78.67638},
        description: "Level 5 Library Elevator Lobby",
        image: AEDicon, //doesn't work
    },
    {
        id: 170,
        name: "Hunt Library",
        address: "---",
        position: { lat: 35.76914, lng: -78.67642},
        description: "Outside of Level 1 Restrooms",
        image: AEDicon, //doesn't work
    },
    {
        id: 171,
        name: "Hunt Library",
        address: "---",
        position: { lat: 35.76909, lng: -78.67644},
        description: "Level 2 Library in Elevator Lobby",
        image: AEDicon, //doesn't work
    },
    {
        id: 172,
        name: "Hunt Library",
        address: "---",
        position: { lat: 35.76906, lng: -78.67639},
        description: "Level 3 Library in Elevator Lobby",
        image: AEDicon, //doesn't work
    },
    {
        id: 173,
        name: "Hunt Library",
        address: "---",
        position: { lat: 35.76955, lng: -78.6765},
        description: "Ground Level Main Entrance Side down by the BookBot",
        image: AEDicon, //doesn't work
    },
    {
        id: 174,
        name: "Research IV",
        address: "---",
        position: { lat: 35.77214, lng: -78.68067},
        description: "Inside of Room 1900 - Right Side of Doorway - Advance Energy",
        image: AEDicon, //doesn't work
    },
    {
        id: 175,
        name: "Research IV",
        address: "---",
        position: { lat: 35.77225, lng: -78.68067},
        description: "Inside of Suite 2100 - 2nd Floor Outside Rm 2125 - Advance Energy",
        image: AEDicon, //doesn't work
    },
    {
        id: 176,
        name: "Joyner Visitor Center",
        address: "---",
        position: { lat: 35.78403, lng: -78.68449},
        description: "Across from Room 149 - Left side of Main Lobby",
        image: AEDicon, //doesn't work
    },
    {
        id: 177,
        name: "Peele Hall",
        address: "---",
        position: { lat: 35.78584, lng: -78.66467},
        description: "Ground Floor Break Room - Room 110",
        image: AEDicon, //doesn't work
    },
    {
        id: 178,
        name: "David Clark Labs",
        address: "---",
        position: { lat: 35.78737, lng: -78.67415},
        description: "1st Floor - Across from Room 168",
        image: AEDicon, //doesn't work
    },
    {
        id: 179,
        name: "EB II",
        address: "---",
        position: { lat: 35.77198, lng: -78.673997},
        description: "3rd Floor Between Rooms 3009 and 3011",
        image: AEDicon, //doesn't work
    },
    {
        id: 180,
        name: "Williams Hall",
        address: "---",
        position: { lat: 35.786826, lng: -78.67183},
        description: "Outside Room 3209",
        image: AEDicon, //doesn't work
    },
    {
        id: 181,
        name: "Williams Hall",
        address: "---",
        position: { lat: 35.786781, lng:-78.671682 },
        description: "1st floor",
        image: AEDicon, //doesn't work
    },
    {
        id: 182,
        name: "Williams Hall",
        address: "---",
        position: { lat: 35.786844, lng:-78.671898 },
        description: "4th floor",
        image: AEDicon, //doesn't work
    },
    {
        id: 183,
        name: "Williams Hall",
        address: "---",
        position: { lat: 35.786854, lng: -78.671899},
        description: "2nd floor",
        image: AEDicon, //doesn't work
    },
    {
        id: 184,
        name: "Broughton Hall",
        address: "---",
        position: { lat: 35.785597, lng: -78.670147},
        description: "Outside of Room 4172",
        image: AEDicon, //doesn't work
    },
    {
        id: 185,
        name: "USDA",
        address: "---",
        position: { lat: 35.78847, lng: -78.69164},
        description: "Outside of Room 3",
        image: AEDicon, //doesn't work
    },
    {
        id: 186,
        name: "MRC",
        address: "---",
        position: { lat: 35.76943, lng: -78.6785},
        description: "Outside of Room 426",
        image: AEDicon, //doesn't work
    },
    {
        id: 187,
        name: "Schaub Hall",
        address: "---",
        position: { lat: 35.783753, lng: -78.678062},
        description: "2nd Floor Outside Room 100",
        image: AEDicon, //doesn't work
    },
    {
        id: 188,
        name: "Construction Facilities Lab",
        address: "---",
        position: { lat: 35.76883, lng: -78.679461},
        description: "Across from Room 102",
        image: AEDicon, //doesn't work
    },
    {
        id: 189,
        name: "Wildlife Building",
        address: "---",
        position: { lat: 35.77455, lng: -78.67812},
        description: "Front Entrance Lobby on Left",
        image: AEDicon, //doesn't work
    },
    {
        id: 190,
        name: "Case Athletic Center",
        address: "---",
        position: { lat: 35.78276, lng: -78.66961},
        description: "Foyer of Main Entrance from Jeter Bay",
        image: AEDicon, //doesn't work
    },
    {
        id: 191,
        name: "Park Shops",
        address: "---",
        position: { lat: 35.78568, lng: -78.66711},
        description: "2nd Floor top of stairs outside Suite 211",
        image: AEDicon, //doesn't work
    },
    {
        id: 192,
        name: "Weaver Labs",
        address: "---",
        position: { lat: 35.783242, lng: -78.6679505},
        description: "Outside Admin in Hallway",
        image: AEDicon, //doesn't work
    },
    {
        id: 193,
        name: "Materials Warehouse",
        address: "---",
        position: { lat: 35.788647, lng: -78.700206},
        description: "By service desk roll-up door",
        image: AEDicon, //doesn't work
    },
    {
        id: 194,
        name: "Polk Hall",
        address: "---",
        position: { lat: 35.786168, lng: -78.670077},
        description: "---",
        image: AEDicon, //doesn't work
    },
    {
        id: 195,
        name: "CVM Research",
        address: "---",
        position: { lat: 35.79742, lng: -78.70547},
        description: "Outside Rm 105",
        image: AEDicon, //doesn't work
    },
    {
        id: 196,
        name: "CVM Research",
        address: "---",
        position: { lat: 35.79742, lng: -78.70544},
        description: "Across from Rm 304",
        image: AEDicon, //doesn't work
    },
    {
        id: 197,
        name: "Page Hall",
        address: "---",
        position: { lat: 35.785969, lng: -78.66703},
        description: "1st Floor",
        image: AEDicon, //doesn't work
    },
    {
        id: 198,
        name: "Admin I",
        address: "---",
        position: { lat: 35.787635, lng: -78.68318},
        description: "Outside Rm 1117",
        image: AEDicon, //doesn't work
    },
    {
        id: 199,
        name: "Thompson Theatre",
        address: "---",
        position: { lat: 35.782733, lng: -78.667042},
        description: "Craft Center",
        image: AEDicon, //doesn't work
    },
    {
        id: 200,
        name: "Gregg Museum",
        address: "---",
        position: { lat: 35.785105, lng: -78.661838},
        description: "Across from front desk by elevator",
        image: AEDicon, //doesn't work
    },
    {
        id: 201,
        name: "1911 Building",
        address: "---",
        position: { lat: 35.78647, lng: -78.66735},
        description: "Outside Room 111",
        image: AEDicon, //doesn't work
    },
    {
        id: 202,
        name: "Withers Hall",
        address: "---",
        position: { lat: 35.786344, lng: -78.667858},
        description: "Outside Room 331",
        image: AEDicon, //doesn't work
    },
    {
        id: 203,
        name: "Lonnie Poole Golf Course",
        address: "---",
        position: { lat: 35.76015, lng: -78.67722},
        description: "In hallway by service elevator and outside room 205 bathroom",
        image: AEDicon, //doesn't work
    },
    {
        id: 204,
        name: "Winston/ Caldwell/ Tompkins",
        address: "---",
        position: { lat: 35.786787, lng: -78.665774},
        description: "Caldwell Hall - Outside Room 106",
        image: AEDicon, //doesn't work
    },
    {
        id: 205,
        name: "Broughton Hall",
        address: "---",
        position: { lat: 35.785547, lng: -78.6704},
        description: "Outside Room 3223",
        image: AEDicon, //doesn't work
    },
    {
        id: 206,
        name: "Holmes Hall",
        address: "---",
        position: { lat: 35.784275, lng: -78.673918},
        description: "Second Floor Elevator Lobby",
        image: AEDicon, //doesn't work
    },
    {
        id: 207,
        name: "DELTA Testing Center - Centennial - 1730 Varsity Drive",
        address: "---",
        position: { lat: 35.773848, lng: -78.678556},
        description: "Venture IV 1730 Varsity Drive - Centennial Campus",
        image: AEDicon, //doesn't work
    },
    {
        id: 208,
        name: "CTI Building - DELTA's Main Office",
        address: "---",
        position: { lat: 35.771105, lng: -78.678017},
        description: "Delta Main Office - CTI Main Campus Suite 220",
        image: AEDicon, //doesn't work
    },
    {
        id: 209,
        name: "Cox Hall - DELTA Testing Center",
        address: "---",
        position: { lat: 35.786037, lng: -78.670915},
        description: "Cox Hall Room 205",
        image: AEDicon, //doesn't work
    },
    {
        id: 210,
        name: "DELTA VCS Office",
        address: "---",
        position: { lat: 35.787166, lng: -78.667431},
        description: "Ricks Hall - Suite 130 - Room 133",
        image: AEDicon, //doesn't work
    },
    {
        id: 211,
        name: "Patterson Hall",
        address: "---",
        position: { lat: 35.787145, lng: -78.668748},
        description: "3rd Floor West Stairwell - DH Hill Side",
        image: AEDicon, //doesn't work
    },
    {
        id: 212,
        name: "Lake Wheeler Field Lab",
        address: "---",
        position: { lat: 35.734353, lng: -78.676339},
        description: "Booth Classroom Building at 4000 Chi Road",
        image: AEDicon, //doesn't work
    },
    {
        id: 213,
        name: "Keystone - Freedm Center",
        address: "---",
        position: { lat: 35.774228, lng: -78.677644},
        description: "Ground floor low bay lab",
        image: AEDicon, //doesn't work
    },
    {
        id: 214,
        name: "Admin III",
        address: "---",
        position: { lat: 35.786484, lng: -78.681623},
        description: "2nd Floor in Suite 240",
        image: AEDicon, //doesn't work
    },
    {
        id: 215,
        name: "Holladay Hall",
        address: "---",
        position: { lat: 35.78551, lng:-78.664012 },
        description: "Ground floor outside break room 8",
        image: AEDicon, //doesn't work
    },
    {
        id: 216,
        name: "Admin II",
        address: "---",
        position: { lat: 35.787077, lng: -78.682389},
        description: "---",
        image: AEDicon, //doesn't work
    },
    {
        id: 217,
        name: "",
        address: "---",
        position: { lat: 35.788732, lng: -78.683255},
        description: "---",
        image: AEDicon, //doesn't work
    },
    {
        id: 218,
        name: "",
        address: "---",
        position: { lat: 35.788392, lng: -78.682307},
        description: "---",
        image: AEDicon, //doesn't work
    },
    {
        id: 219,
        name: "",
        address: "---",
        position: { lat: 35.770036, lng: -78.678184},
        description: "Outside room 2322",
        image: AEDicon, //doesn't work
    },
];

export default NCStateAEDs;
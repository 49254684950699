import AEDicon from '../../images/AEDicon.jpg';

const UNCAEDs = [
    {
        id: 1,
        name: "Anderson Stadium",
        address: "912 Raleigh Rd",
        position: { lat: 35.90659600175478, lng: -79.03134286626278  },
        description: "Stadium",
        image: AEDicon, //doesn't work
    },
    {
        id: 2,        
        name: "BerryHill Hall",
        address: "150 Medical Drive",
        position: { lat: 35.90634011978672, lng: -79.0516802644174 },
        description: "Main Enterance - by student lounge/ elevators",
        image: AEDicon, //doesn't work
    },
    {
        id: 3,        
        name: "Bioinformatics Building",
        address: "130 Mason Farm Rd",
        position: { lat: 35.9016729165966, lng: -79.0532924116643 },
        description: "5th Floor - Room 514", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 4,        
        name: "Bioinformatics Building",
        address: "130 Mason Farm Rd",
        position: { lat: 35.9014460457825, lng: -79.0533253620782 },
        description: "Break Room", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 5,        
        name: "Bioinformatics Building",
        address: "130 Mason Farm Rd",
        position: { lat: 35.9015287869783, lng: -79.0535131794380 },
        description: "Ground Floor near Men's Restroom and Main Auditorium", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 6,       
        name: "Bondurant Hall",
        address: "321 S Columbia St",
        position: { lat: 35.90587765052451, lng: -79.05239097134994 },
        description: "Ground Floor Hall near G060", 
        image: AEDicon, //doesn't work
    },
    {
        id: 7,
        name: "Boshamer Stadium",
        address: "235 Ridge Rd",
        position: { lat: 35.90641263946425, lng:  -79.04366101552417 },
        description: "Main Level", 
        image: AEDicon, //doesn't work
    },
    {
        id: 8,        
        name: "Brinkhous-Bullitt Building",
        address: "160 Medical Dr",
        position: { lat: 35.90595235049983, lng: -79.05178640387254 },
        description: "Beach Cafe entrance", 
        image: AEDicon, //doesn't work
    },
    {
        id: 9,        
        name: "Carmichael Arena",
        address: "310 South Rd",
        position: { lat: 35.90960134929691, lng: -79.04519304067117 },
        description: "Arena", 
        image: AEDicon, //doesn't work
    },
    {
        id: 10,        
        name: "Carrington Hall",
        address: "120 Medical Dr",
        position: { lat: 35.9069339903147, lng: -79.0522414144508 },
        description: "1st Floor by Room 1100", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 11,        
        name: "Carrington Hall",
        address: "120 Medical Dr",
        position: { lat: 35.9068033301860, lng: -79.0523054982907 },
        description: "2nd Floor - new bldg hall connecting to old bldg", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 12,        
        name: "Carrington Hall",
        address: "120 Medical Dr",
        position: { lat: 35.9067639531186, lng: -79.0520977782580 },
        description: "3rd Floor - old bldg hall connecting to new bldg", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 13,        
        name: "Carrington Hall",
        address: "120 Medical Dr",
        position: { lat: 35.9067120469543, lng: -79.0519784497286 },
        description: "4th Floor - new bldg hall connecting to old bldg", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 14,        
        name: "Carrington Hall",
        address: "120 Medical Dr",
        position: { lat: 35.9068498666949, lng: -79.0517530513952 },
        description: "5th Floor - new bldg hall connecting to old bldg", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 15,        
        name: "Carrington Hall",
        address: "120 Medical Dr",
        position: { lat: 35.9066834090560, lng: -79.0516690794671 },
        description: "Ground Floor Besides room 01 - opposite side of new bldg", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 16,        
        name: "Carrington Hall",
        address: "120 Medical Dr",
        position: { lat: 35.9066279230986, lng: -79.0514525202841 },
        description: "L level by L200 in new bldg", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 17,        
        name: "Cheek Clark Bldg",
        address: "601 W Cameron Ave",
        position: { lat: 35.90661226829108, lng: -79.06276859164933 },
        description: "Housekeeping Break Room", 
        image: AEDicon, //doesn't work
    },
    {
        id: 18,
        name: "Cobb Chiller Plant",
        address: "365 Paul Green Theatre Dr",
        position: { lat: 35.91177928004374, lng: -79.04495735785433 },
        description: "Main Level", 
        image: AEDicon, //doesn't work
    },
    {
        id: 19,
        name: "Cogeneration Facility",
        address: "555 W Cameron Ave",
        position: { lat: 35.9066471973397, lng: -79.0618964396424 },
        description: "MDSP", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 20,
        name: "Cogeneration Facility",
        address: "555 W Cameron Ave",
        position: { lat: 35.9065816249216, lng: -79.0620082362986 },
        description: "Admin Bldg", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 21,
        name: "Cogeneration Facility",
        address: "555 W Cameron Ave",
        position: { lat: 35.9065441549440, lng: -79.0619092898327 },
        description: "Control Room", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 22,
        name: "Cogeneration Facility",
        address: "555 W Cameron Ave",
        position: { lat: 35.9064577657613, lng: -79.0619337051944 },
        description: "Boiler Room", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 23,
        name: "Cogeneration Facility",
        address: "555 W Cameron Ave",
        position: { lat: 35.9064983582806, lng: -79.0618141984240 },
        description: "Coal Handling", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 24,
        name: "Cone Kenfield Tennis Center",
        address: "251 Friday Center Dr",
        position: { lat: 35.89417571585917, lng: -79.01102907503798 },
        description: "Main Level", 
        image: AEDicon, //doesn't work
    },
    {
        id: 25,
        name: "Daniels Student Stores",
        address: "207 South Rd",
        position: { lat: 35.90983316976873, lng: -79.04836567319374 },
        description: "Second Floor near Bathrooms, Cafe", 
        image: AEDicon, //doesn't work
    },
    {
        id: 26,
        name: "Davis Library",
        address: "208 Raleigh St",
        position: { lat: 35.91101322167397, lng: -79.04802281552408 },
        description: "Opposite Circulation Desk", 
        image: AEDicon, //doesn't work
    },
    {
        id: 27,
        name: "East Chiller Plant",
        address: "340 Skipper Bowles Dr",
        position: { lat: 35.89873666771837, lng: -79.04389059887025 },
        description: "Main Level", 
        image: AEDicon, //doesn't work
    },
    {
        id: 28,
        name: "Electrical Distribution Operations Center",
        address: "112 Airport Dr",
        position: { lat: 35.93209025194453, lng: -79.06065920224144 },
        description: "Admin Bldg", 
        image: AEDicon, //doesn't work
    },
    {
        id: 29,
        name: "Enviornment Health and Safety Bldg",
        address: "1120 Estes Drive Ext",
        position: { lat: 35.933801593887566, lng: -79.05856614510827 },
        description: "Front Lobby", 
        image: AEDicon, //doesn't work
    },
    {
        id: 30,
        name: "Fetzer Hall",
        address: "210 South Rd",
        position: { lat: 35.9094707625030, lng: -79.0469607774595 },
        description: "1st Floor Reception Area across from Equipment room", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 31,
        name: "Fetzer Hall",
        address: "210 South Rd",
        position: { lat: 35.9094059719535, lng: -79.0467207961997 },
        description: "Expeditions/ Toni's Office", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 32,
        name: "Fetzer Hall",
        address: "210 South Rd",
        position: { lat: 35.9091745767008, lng: -79.0468274545374 },
        description: "Gym A (between A and B)", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 33,
        name: "Fetzer Hall",
        address: "210 South Rd",
        position: { lat: 35.9088691339311, lng: -79.0468922113853 },
        description: "Gym B", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 34,
        name: "Fetzer Hall",
        address: "210 South Rd",
        position: { lat: 35.9089740336011, lng: -79.0471474295505 },
        description: "Gym C", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 35,
        name: "Fetzer Hall",
        address: "210 South Rd",
        position: { lat: 35.9090203128230, lng: -79.0476616751074 },
        description: "4 Labs", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 36,
        name: "Finley Club House Building",
        address: "500 Finley Golf Course Rd",
        position: { lat: 35.89697004943567, lng: -79.02205880552077 },
        description: "Main Level", 
        image: AEDicon, //doesn't work
    },
    {
        id: 37,
        name: "Friday Center, William, and Ida",        
        address: "100 Friday Center Dr",
        position: { lat: 35.89997309734592, lng: -79.01256045416687 },
        description: "Hall Phone Closet", 
        image: AEDicon, //doesn't work

    },
    {
        id: 38,
        name: "Genetic Medicine Research Building",
        address: "120 Mason Farm Rd",
        position: { lat: 35.90127300805843, lng: -79.05436438853333 },
        description: "Main Entrance - near restrooms in lobby", 
        image: AEDicon, //doesn't work
    },
    {
        id: 39,
        name: "General Storeroom",
        address: "111 Airport Dr",
        position: { lat: 35.932897264333185, lng: -79.05861105339655 },
        description: "Reception Area/ HVAC", 
        image: AEDicon, //doesn't work
    },
    {
        id: 40,
        name: "Glaxo Research Building",
        address: "101 Mason Farm Rd",
        position: { lat: 35.902529924238515, lng: -79.055885176069 },
        description: "Main Entrance (Mason Farm parking side)", 
        image: AEDicon, //doesn't work
    },
    {
        id: 41,
        name: "Graham Student Union",
        address: "209 South Rd",
        position: { lat: 35.9102204645032, lng: -79.0479674254273 },
        description: "2nd Floor West Lounge, near main elevator", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 42,
        name: "Graham Student Union",
        address: "209 South Rd",
        position: { lat: 35.9104586591314, lng: -79.0471954372843 },
        description: "2nd Floor West Lounge, near main elevator", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 43,
        name: "Graham Student Union",
        address: "209 South Rd",
        position: { lat: 35.9099673819300, lng: -79.0476447425464 },
        description: "2nd Floor West Lounge, near main elevator", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 44,
        name: "Hanes Art Center",
        address: "121 E Cameron Ave",
        position: { lat: 35.91231714335374, lng: -79.0543879731937 },
        description: "BeAM Shop", 
        image: AEDicon, //doesn't work
    },
    {
        id: 45,
        name: "Hooker Research Center",
        address: "135 Dauer Dr",
        position: { lat: 35.905461389841626, lng: -79.05420035969837 },
        description: "1st Floor East Entrance", 
        image: AEDicon, //doesn't work
    },
    {
        id: 46,
        name: "House Undergraduate Library",
        address: "203 South Rd",
        position: { lat: 35.909777469774916, lng: -79.0490640020286 },
        description: "Left of Service Desk", 
        image: AEDicon, //doesn't work
    },
    {
        id: 47,
        name: "Horney Bldg",
        address: "103 Aiport Dr",
        position: { lat: 35.9326871272025, lng: -79.0576133355700 },
        description: "HVAC Hall by Restroom", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 48,
        name: "Horney Bldg",
        address: "103 Aiport Dr",
        position: { lat: 35.9322563688426, lng: -79.0574498590485 },
        description: "1st Floor", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 49,
        name: "Jones Bldg, Mary Ellen",
        address: "116 Manning Dr",
        position: { lat: 35.903599008824045, lng: -79.05343170202877 },
        description: "Main entrance - in lobby", 
        image: AEDicon, //doesn't work
    },
    {
        id: 50,
        name: "Kenan Football Center",
        address: "1 Stone Center Dr",
        position: { lat: 35.90730642004651, lng: -79.04891238853314 },
        description: "Weight Room", 
        image: AEDicon, //doesn't work
    },
    {
        id: 51,
        name: "Knapp-Sanders Building",
        address: "400 South Rd",
        position: { lat: 35.91018529035927, lng: -79.04207939037704 },
        description: "Lobby near room 2405", 
        image: AEDicon, //doesn't work
    },
    {
        id: 52,
        name: "Lineberger Cancer Research Center",
        address: "450 West Dr",
        position: { lat: 35.9031261257951, lng: -79.0536842137348 },
        description: "Main Entrance - by elevator", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 53,
        name: "Lineberger Cancer Research Center",
        address: "450 West Dr",
        position: { lat: 35.9027416959346, lng: -79.0534130149252 },
        description: "First Floor Elevator Lobby", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 54,
        name: "Liquid Storage Facility",
        address: "",
        position: { lat: 35.93416405927875, lng: -79.05785897622175 },
        description: "1075 Facilitis Dr", 
        image:"Office A //doesn't workrea",
    },
    {
        id: 55,
        name: "Loudermilk Center for Excellence",
        address: "344 Ridge Rd",
        position: { lat: 35.9063155618329, lng: -79.0471827258952 },
        description: "Concourse Club", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 56,
        name: "Loudermilk Center for Excellence",
        address: "344 Ridge Rd",
        position: { lat: 35.9065993573602, lng: -79.0469544813541 },
        description: "Upper Club", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 57,
        name: "Loudermilk Center for Excellence",
        address: "344 Ridge Rd",
        position: { lat: 35.9069253155384, lng: -79.0467362475385 },
        description: "Olympic Weigt Room 1st Floor", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 58,
        name: "MacNll",
        address: "333 S Columbia St",
        position: { lat: 35.90538741920401, lng: -79.05256818853321 },
        description: "Main Entrance - 1st Floor (entrance facing Health Sciences Library)", 
        image: AEDicon, //doesn't work
    },
    {
        id: 59,
        name: "McGavran-Greenberg Hall",
        address: "401 Pittsboro St",
        position: { lat: 35.9066029641933, lng: -79.05604188308058 },
        description: "1st Floor West Entrance", 
        image: AEDicon, //doesn't work
    },
    {
        id: 60,
        name: "Medical Biomolecular Research Bldg",
        address: "111 Mason Farm Rd",
        position: { lat: 35.90285617053635, lng: -79.05533727319396 },
        description: "Main Lobby by Restrooms", 
        image: AEDicon, //doesn't work
    },
    {
        id: 61,
        name: "Medical Research Bldg B",
        address: "150 Bragg Dr",
        position: { lat: 35.90065369140462, lng: -79.05272673100146 },
        description: "Main Entrance", 
        image: AEDicon, //doesn't work
    },
    {
        id: 62,
        name: "Memorial Hall",
        address: "140 E Cameron Ave",
        position: { lat: 35.9112603119928, lng: -79.0520783282632 },
        description: "Coat Check Door", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 63,
        name: "Memorial Hall",
        address: "140 E Cameron Ave",
        position: { lat: 35.9109948540203, lng: -79.0519443242256 },
        description: "Backstage Abv. Extinguisher", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 64,
        name: "Murray Hall",
        address: "121 South Rd",
        position: { lat: 35.9094847814139, lng: -79.0516769880126 },
        description: "BeAM Wood Shop", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 65,
        name: "Murray Hall",
        address: "121 South Rd",
        position: { lat: 35.9097380370418, lng: -79.0518242155005 },
        description: "BeAM Metal Shop", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 66,
        name: "Naval Armory",
        address: "221 S Columbia St",
        position: { lat: 35.9091899291979, lng: -79.0528814232520 },
        description: "Floater/ Traveling?", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 67,
        name: "Naval Armory",
        address: "221 S Columbia St",
        position: { lat: 35.9090384349595, lng: -79.0527706747988 },
        description: "Main Floor Near Room 108, Ladies Restroom", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 68,
        name: "NC Area Health Education Center Bldg",
        address: "145 Medical Drive",
        position: { lat: 35.9070120321178, lng: -79.0514045449769 },
        description: "Main Entrance - Medical Drive (Floor 3)", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 69,
        name: "NC Area Health Education Center Bldg",
        address: "145 Medical Drive",
        position: { lat: 35.9069262179874, lng: -79.0512657406762 },
        description: "5th Floor", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 70,
        name: "Neurosciences Research Bldg",
        address: "115 Mason Farm Rd",
        position: { lat: 35.90324735649847, lng: -79.05502477319395 },
        description: "Main Entrance - by elevator", 
        image: AEDicon, //doesn't work
    },
    {
        id: 71,
        name: "North Chiller Plant",
        address: "155 Medical Dr",
        position: { lat: 35.90610994913837, lng: -79.05044780202869 },
        description: "Main Level", 
        image: AEDicon, //doesn't work
    },
    {
        id: 72,
        name: "Physician Office Bldg",
        address: "170 Manning Dr",
        position: { lat: 35.90264823994501, lng: -79.04995035865828 },
        description: "Main Entrance basement level - facing Jackson Parking deck", 
        image: AEDicon, //doesn't work
    },
    {
        id: 73,
        name: "Public Safety Bldg",
        address: "286 Manning Dr",
        position: { lat: 35.9041473363474, lng: -79.0473793830503 },
        description: "Coat Check, Main level", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 74,
        name: "Public Safety Bldg",
        address: "286 Manning Dr",
        position: { lat: 35.9039201874362, lng: -79.0471268626005 },
        description: "Front Hall", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 75,
        name: "Rams Head Recreation",
        address: "340 Ridge Rd",
        position: { lat: 35.90582403087836, lng: -79.0464375596984 },
        description: "Front Desk", 
        image: AEDicon, //doesn't work
    },
    {
        id: 76,
        name: "Rosenau Hall",
        address: "421 Pittsboro St",
        position: { lat: 35.90588808004503, lng: -79.05374704620296 },
        description: "1st Floor East Entrance", 
        image: AEDicon, //doesn't work
    },
    {
        id: 77,
        name: "Smith, Eddie Field House",
        address: "210 Ridge Rd",
        position: { lat: 35.9094012827921, lng: -79.0432520427294 },
        description: "Track Level", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 78,
        name: "Smith, Eddie Field House",
        address: "210 Ridge Rd",
        position: { lat: 35.9089040191250, lng: -79.0430734405047 },
        description: "Belk Track Indoor", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 79,
        name: "Smith Student Activities Center",
        address: "300 Skipper Bowles Dr",
        position: { lat: 35.8997098852631, lng: -79.0436216821560 },
        description: "Arena, Main court", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 80,
        name: "Smith Student Activities Center",
        address: "300 Skipper Bowles Dr",
        position: { lat: 35.8994443529662, lng:-79.0443076945314  },
        description: "Koury Natatorium", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 81,
        name: "Smith Student Activities Center",
        address: "300 Skipper Bowles Dr",
        position: { lat: 35.8992527311675, lng: -79.0436622346117 },
        description: "Practice Gym", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 82,
        name: "South Bldg",
        address: "200 E Cameron Ave",
        position: { lat: 35.91174626955808, lng: -79.0510677308634 },
        description: "Lobby", 
        image: AEDicon, //doesn't work
    },
    {
        id: 83,
        name: "South Chiller Plant",
        address: "160 Bragg Dr",
        position: { lat: 35.90034708928113, lng: -79.05391550202889 },
        description: "Main Level", 
        image: AEDicon, //doesn't work
    },
    {
        id: 84,
        name: "Student Recreation Center",
        address: "208 South Rd",
        position: { lat: 35.90926132899015, lng: -79.0474872596983 },
        description: "Front Desk by Staircase", 
        image: AEDicon, //doesn't work
    },
    {
        id: 85,
        name: "Tate-Turner-Kuralt Bldg",
        address: "325 Pittsboro St",
        position: { lat: 35.9069952518545, lng: -79.0542724812203 },
        description: "1st Floor Lobby - Closet adjacent to front entrance (100 A)", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 86,
        name: "Tate-Turner-Kuralt Bldg",
        address: "325 Pittsboro St",
        position: { lat: 35.9072288763521, lng: -79.0542209450387 },
        description: "4th Floor", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 87,
        name: "Taylor Campus Health",
        address: "320 Emergency Room Dr",
        position: { lat: 35.90588860957776, lng: -79.04953784435901 },
        description: "1st Floor CHC", 
        image: AEDicon, //doesn't work
    },
    {
        id: 88,
        name: "Taylor Hall",
        address: "109 Mason Farm Rd",
        position: { lat: 35.90260901734661, lng: -79.05483512848132 },
        description: "Main entrance by elevators", 
        image: AEDicon, //doesn't work
    },
    {
        id: 89,
        name: "Thurston-Bowles Bldg",
        address: "104 Manning Dr",
        position: { lat: 35.9038971252014, lng: -79.0545751339859 },
        description: "Main Entrance at Bridge level, 2nd Floor", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 90,
        name: "Thurston-Bowles Bldg",
        address: "104 Manning Dr",
        position: { lat: 35.9037099702819, lng: -79.0544330170319 },
        description: "Second Floor outside elevator area near restrooms", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 91,
        name: "Tomkins Chilled Water Operations Center",
        address: "140 Mason Farm Rd",
        position: { lat: 35.9015316460241, lng: -79.0524436848713 },
        description: "Main Lobby", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 92,
        name: "Tomkins Chilled Water Operations Center",
        address: "140 Mason Farm Rd",
        position: { lat: 35.9016150753028, lng: -79.0526609417479 },
        description: "4th Floor", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 93,
        name: "Van Hecke-Wettach Hall",
        address: "160 Ridge Rd",
        position: { lat: 35.9088449271302, lng: -79.0421345887652 },
        description: "Library 4th Floor spiral Stairwell", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 94,
        name: "Van Hecke-Wettach Hall",
        address: "160 Ridge Rd",
        position: { lat: 35.9086469858927, lng: -79.0424670678283 },
        description: "Portico", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 95,
        name: "Van Hecke-Wettach Hall",
        address: "160 Ridge Rd",
        position: { lat: 35.9085905955658, lng: -79.0426389907626 },
        description: "Across from Faculty Admin", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 96,
        name: "Van Hecke-Wettach Hall",
        address: "160 Ridge Rd",
        position: { lat: 35.9082879284308, lng: -79.0425849984361 },
        description: "Meadowmont Kitchen", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 97,
        name: "Van Hecke-Wettach Hall",
        address: "160 Ridge Rd",
        position: { lat: 35.9082039179560, lng: -79.0420578628275 },
        description: "Across from Learning Resources", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 98,
        name: "Van Hecke-Wettach Hall",
        address: "160 Ridge Rd",
        position: { lat: 35.9085296019017, lng: -79.0421374304665 },
        description: "Front Entry by main desk", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 99,
        name: "Williamson Athletic Center",
        address: "450 Skipper Bowles Dr",
        position: { lat: 35.89988778004642, lng: -79.0422099308637 },
        description: "1st Floor", 
        image: AEDicon, //doesn't work
    },
    {
        id: 100,
        name: "Wilson Library",
        address: "201 South Rd",
        position: { lat: 35.909434480044226, lng: -79.04974915969832 },
        description: "Right of Pleasants Family Assembly Room", 
        image: AEDicon, //doesn't work
    },
    {
        id: 101,
        name: "Wollen Gym",
        address: "300 South Rd",
        position: { lat: 35.9098331132481, lng: -79.0458618155981 },
        description: "Training Room near outdoor pool", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 102,
        name: "Wollen Gym",
        address: "300 South Rd",
        position: { lat: 35.9096645706802, lng: -79.0460360927835 },
        description: "Wall Mounted", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 103,
        name: "Wollen Gym",
        address: "300 South Rd",
        position: { lat: 35.9095508042440, lng: -79.0461869596007 },
        description: "Training Room near outdoor pool", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 104,
        name: "Wollen Gym",
        address: "300 South Rd",
        position: { lat: 35.9093401252263, lng: -79.0461635492325 },
        description: "Reception Desk Lower Level", 
        image: AEDicon, //doesn't work
    },
    // fix location
    {
        id: 105,
        name: "Wollen Gym",
        address: "300 South Rd",
        position: { lat: 35.9092558534622, lng: -79.0458123937097 },
        description: "Equipment Room", 
        image: AEDicon, //doesn't work
    },
    // fix location
];

export default UNCAEDs;
import AEDicon from '../../images/AEDicon.jpg';

const DukeAEDs = [
    {
        id: 225,
        name: "Rubenstein Library",
        address: "411 Chapel Dr",
        position: { lat: 36.0019638103727, lng: -78.9387934218741},
        description: "First Floor - between women's restroom and elevator",
        image: AEDicon, //doesn't work
    },
    {
        id: 226,
        name: "Rubenstein Library",
        address: "411 Chapel Dr",
        position: { lat: 36.0017598597686, lng:-78.9386951018059 },
        description: "Third Floor - between women's restroom and elevator",
        image: AEDicon, //doesn't work
    },
    {
        id: 227,
        name: "Perkins and Bostock Libraries",
        address: "Campus Dr",
        position: { lat: 36.0024847091654, lng:-78.9386219039732 },
        description: "The Link/ Lower Level One, next to the Fish Grand Stiarway",
        image: AEDicon, //doesn't work
    },
    {
        id: 228,
        name: "Perkins and Bostock Libraries",
        address: "Campus Dr",
        position: { lat: 36.0022733852634, lng:-78.9384836117922 },
        description: "Third floor, next to the fish Grand Stairway",
        image: AEDicon, //doesn't work
    },
];

export default DukeAEDs;
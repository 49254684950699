import React, { Component } from 'react';
import './Navbar.css'
import fosappicon from "../images/fosappiconwborder.png";

export default function Navbar () {
    return (
        
        <nav className="nav">
            <a href="/" className="site-title">
                <img src={fosappicon} className="fosappicon"/>First on Scene
            </a>
            <ul>
                <li>
                    <a href="/support" className="tab"></a>
                </li>
                <li>
                    <a href="/about" className="tab"></a>
                </li>
            </ul>

        </nav>
    )
}
import React, { useState, Image } from 'react'
import { GoogleMap, useLoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import "./AEDmap.css";
import UNCAEDs from './AEDlocations/UNCAEDs';
import NCStateAEDs from './AEDlocations/NCStateAEDs';
import DukeAEDs from './AEDlocations/DukeAEDs';
import UNCAEDIcon from '../images/AEDiconCarolinaBlue.png';
import NCSUAEDIcon from '../images/AEDiconNCState.png';
import DukeAEDIcon from '../images/AEDiconDuke.png';
import Dukelogo from '../images/UniversityDukelogo.png';
import UNClogo from '../images/UniversityUNClogo.png';
import NCSUlogo from '../images/UniversityNCStatelogo.png';
import AEDicon from '../images/AEDicon.jpg';

const mapContainerStyle = {
  width: '100vw',
  height: '100vh'
};
const center = {
  lat: 35.8913514949194, 
  lng: -78.87342023466086,
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
// options not working

export default function AEDMap() {
  const [markers, setMarkers] = useState(null);

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAMRoUjAeDVzQziE28UFZ9Pmv3sR07V1sQ",
  });

  if (loadError) return  "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onLoad = () => {
    UNCAEDs.forEach(aed => {
      aed.img = UNCAEDIcon
    });
    NCStateAEDs.forEach(aed => {
      aed.img = NCSUAEDIcon
    });
    DukeAEDs.forEach(aed => {
      aed.img = DukeAEDIcon
    });
    setMarkers([...UNCAEDs, ...NCStateAEDs, ...DukeAEDs]);
  }

  return (
    <div className="header">
      <h1>AED Map</h1>
      <GoogleMap onLoad={() => setTimeout(() => onLoad())} mapContainerStyle={mapContainerStyle} zoom={10} center={center} options={options}>
        
        <Marker key={1} icon={{url: UNClogo, scaledSize: { width: 60, height: 60 }}} position={{ lat: 35.90958708610441, lng: -79.04868567422402 }}></Marker>
        <Marker key={1} icon={{url: Dukelogo, scaledSize: { width: 50, height: 50 }}} position={{ lat: 36.00195753145023, lng: -78.94034030153156 }}></Marker>
        <Marker key={1} icon={{url: NCSUlogo, scaledSize: { width: 50, height: 50 }}} position={{ lat: 35.78485469243331, lng: -78.6825344978529 }}></Marker>
        

        {markers ? markers.map((marker, index) => 
          <Marker 
            key={index}
            icon={{
              url: marker.img,
              scaledSize: { width: 30, height: 30 }
            }}
            position={marker.position}
            onClick={() => handleActiveMarker(marker.id)}>
              {activeMarker === marker.id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <div>
                    <h2>Building: {marker.name}</h2>
                    <h3>Address: {marker.address}</h3>
                    <h4>Description: {marker.description}</h4>
                    <img src={AEDicon} className="AEDicon" />
                  </div>
                </InfoWindow>
              ) : null}
          </Marker>) : null}

      </GoogleMap>
    </div>
  );
}
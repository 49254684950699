import React from 'react'
import { GoogleMap, useLoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import Navbar from './components/Navbar';
import Support from './screens/Support';
import About from './screens/About';
import AEDmap from './screens/AEDmap';


export default function App() {
  let component
  switch (window.location.pathname) {
    case "/":
      component = <AEDmap />
      break
    case "/support":
      component = <Support />
      break
    case "/about":
      component = <About />
      break
  }

  return (
    <div>
      <Navbar />
      {component}

    </div>
  );
}